import { ActionCategory } from '@core/referential/action-category.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { z } from 'zod'

import { parseDateRange } from '@/lib/date'
import { isUseCase } from '@/lib/utils'

const defaultDateRange = parseDateRange({
  startDate: dayjs().subtract(30, 'day').toDate(),
  endDate: dayjs().toDate(),
})

export const SearchSchema = z.object({
  tab: z.nativeEnum(ActionCategory).default(ActionCategory.SAMPLE),
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/site/$id/actions-done/')({
  beforeLoad() {
    if (isUseCase(UseCase.COLD_STORAGE)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  validateSearch: SearchSchema,
})
