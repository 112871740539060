import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'

import PendingComponent from '@/components/pending-component'
import { isUseCase } from '@/lib/utils'
import { userActionsQuery } from '@/queries/user-actions.query'

export const Route = createFileRoute('/_dashboard/my-actions/')({
  loader({ context }) {
    return context.queryClient.ensureQueryData(userActionsQuery)
  },
  pendingComponent: PendingComponent,
  beforeLoad() {
    if (isUseCase(UseCase.COLD_STORAGE)) {
      throw redirect({ to: '/' })
    }
  },
})
